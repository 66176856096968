@import "../utilities/themes";

.construction-wrapper{
    padding: 4em 1em 2em 1em;
    min-height: 80%;
    min-height: 80vh;
    align-items: center;
    .construction-text {
      @include themed() {
        color: t($main-heading);
      }
      font-size: 3em;
      font-weight: 700;
      padding-top: 2em;
      padding-bottom: 0.5em;
      text-align: center;
      display: block;
    }
    img {
	    -webkit-animation: shake-vertical 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
	            animation: shake-vertical 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
    }
}

@media only screen and (max-width: 768px) {
    .construction-text { 
        font-size: 1.5em;
    }
    .construction-wrapper img { 
        height: 15em;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-22 1:26:12
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-vertical
 * ----------------------------------------
 */
@-webkit-keyframes shake-vertical {
    0%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    10%,
    30%,
    50%,
    70% {
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px);
    }
    20%,
    40%,
    60% {
      -webkit-transform: translateY(8px);
              transform: translateY(8px);
    }
    80% {
      -webkit-transform: translateY(6.4px);
              transform: translateY(6.4px);
    }
    90% {
      -webkit-transform: translateY(-6.4px);
              transform: translateY(-6.4px);
    }
}
@keyframes shake-vertical {
    0%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    10%,
    30%,
    50%,
    70% {
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px);
    }
    20%,
    40%,
    60% {
      -webkit-transform: translateY(8px);
              transform: translateY(8px);
    }
    80% {
      -webkit-transform: translateY(6.4px);
              transform: translateY(6.4px);
    }
    90% {
      -webkit-transform: translateY(-6.4px);
              transform: translateY(-6.4px);
    }
}