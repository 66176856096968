@import '~antd/dist/antd.css';
@import '../utilities/themes';

.App {
    text-align: center;
    height: 100vh;
    overflow-x: hidden;
    @include themed() {
        background-color: t($main-background-1);
        background: -webkit-linear-gradient(to left, t($main-background-1), t($main-background-2), t($main-background-3));  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to left, t($main-background-1), t($main-background-2), t($main-background-3)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
}

.theme-toggle {
    width: 2.5em;
    position: fixed;
    margin: 2em 3em 0em 0em;
    right: 0;
    top: 0;
    z-index: 2000;
    cursor: pointer;
}

.theme-toggle-project {
    width: 2.5em;
    position: fixed;
    margin: 2em 3em 0em 0em;
    right: 0;
    top: 0;
    z-index: 2000;
    cursor: pointer;
}


@media (max-width: 321px) {
    .theme-toggle {
        margin: 2.2em 6em 0em 0em;
    }
    .theme-toggle-project {
        margin: 1em 1em 0em 0em;
    }
}

@media (min-width: 321px) and (max-width: 768px) {
    .theme-toggle {
        margin: 2.2em 7em 0em 0em;
    }
    .theme-toggle-project {
        margin: 1em 1em 0em 0em;
    }
}

@media (min-width: 768px) and (max-width: 1000px) {
    .theme-toggle {
        margin: 2.2em 8em 0em 0em;
    }
    .theme-toggle-project {
        margin: 1em 1em 0em 0em;
    }
}

.vertical-center {
    padding: 0em 2em 0em 2em;
    min-height: 80%;
    min-height: 80vh;
    display: flex;
    align-items: center;
}

p {
    @include themed() {
        color: t($main-paragraph);
    }
    padding: 0em 1vw 0em 1vw;
}

/* Headings */

h1 { /* Main Headings */
    @include themed() {
        color: t($main-heading);
    }
    font-weight: 700;
    font-size: 4em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

h2 { /* Home Page & 404 Page*/
    @include themed() {
        color: t($home-heading);
    }
    font-weight: 700;
    font-size: 5em;
    text-align: left;
    padding: 0em 1vw 0em 1vw;
    -webkit-animation: text-focus-in 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: text-focus-in 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

h3 { /* Home Page */
    @include themed() {
        color: t($home-heading);
    }
    font-weight: 700;
    font-size: 4em;
    text-align: left;
    padding: 0em 1vw 0em 1vw;
    -webkit-animation: text-focus-in1 1.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: text-focus-in1 1.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

h4 { /* Home Page */
    @include themed() {
        color: t($home-heading);
    }
    font-weight: 400;
    font-size: 3em;
    text-align: left;
    padding: 0em 1vw 0em 1vw;
    -webkit-animation: text-focus-in2 1.4s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: text-focus-in2 1.4s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

h5 { /* Project Headings */
    @include themed() {
        color: t($main-heading);
    }
    font-weight: 700;
    font-size: 4em;
    text-align: center;
    padding: 0em 1vw 0em 1vw;
}

@media (max-width: 768px) {
    .verticalCenter {
        padding: 0em 1em 0em 1em;
    }
    h1 {
        font-size: 2.5em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        text-align: center;
    }
    h2 {
        font-size: 2.5em;
        text-align: center;
    }
    h3 {
        font-size: 2em;
        text-align: center;
    }
    h4 {
        font-size: 1.5em;
        text-align: center;
    }
    h5 {
        font-size: 2.5em;
    }
}

/* For H1 */

@-webkit-keyframes text-focus-in {
    0% {
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
    }
    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 0.8;
    }
}

@keyframes text-focus-in {
    0% {
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
    }
    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 0.8;
    }
}

/* For H2 */

@-webkit-keyframes text-focus-in {
    0% {
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
    }
    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

@keyframes text-focus-in {
    0% {
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
    }
    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

/* For H3 */

@-webkit-keyframes text-focus-in1 {
    0% {
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
    }
    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 0.7;
    }
}

@keyframes text-focus-in1 {
    0% {
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
    }
    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 0.7;
    }
}

/* For H4 */

@-webkit-keyframes text-focus-in2 {
    0% {
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
    }
    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 0.5;
    }
}

@keyframes text-focus-in2 {
    0% {
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
    }
    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 0.5;
    }
}
  