@import "../utilities/themes";

.projects-container {
    padding-top: 7em;
    text-align: center;
}

.project-grid {
    max-width: 80em;
    margin: 0em auto 3em auto;
    text-align: center;
    a {
        width: min-content;
    }
}

@media only screen and (max-width: 768px) {
    .project-grid {
        max-width: 20em;
    }
}

@media only screen and (min-width: 570px) and (max-width: 900px) {
    .project-grid {
        max-width: 40em;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1180px) {
    .project-grid {
        max-width: 60em;
    }
}

.project-card {
    height: 20em;
    width: 20em;
    max-width: 100% !important;
    opacity: 0.8;
    &:hover {
        opacity: 1;
        transform: scale(1.05);
        border-radius: 8px;
        z-index: 1;
    }
}

#personal-website-card {
    background-image: url("../images/project-cards/personal-website-card.jpg");
    background-size: cover;
}

#timeline-card {
    background-image: url("../images/project-cards/timeline-card.jpg");
    background-size: cover;
}  

#kafe-krayon-card {
    background-image: url("../images/project-cards/kafe-krayon-card.jpg");
    background-size: cover;
}

#upskill-card {
    background-image: url("../images/project-cards/upskill-card.jpg");
    background-size: cover;
}

#tetris-card {
    background-image: url("../images/project-cards/tetris-card.jpg");
    background-size: cover;
}

#learnfromakiwi-card {
    background-image: url("../images/project-cards/learnfromakiwi-card.jpg");
    background-size: cover;
}

#learnfromakiwi-card {
    background-image: url("../images/project-cards/learnfromakiwi-card.jpg");
    background-size: cover;
}

#money-busters-card {
    background-image: url("../images/project-cards/money-busters-card.jpg");
    background-size: cover;
}

#scammr-card {
    background-image: url("../images/project-cards/scammr-card.jpg");
    background-size: cover;
}

#task-manager-card {
    background-image: url("../images/project-cards/task-manager-card.jpg");
    background-size: cover;
}

#eventigate-card {
    background-image: url("../images/project-cards/eventigate-card.jpg");
    background-size: cover;
}

#moving-shapes-card {
    background-image: url("../images/project-cards/moving-shapes-card.jpg");
    background-size: cover;
}

