@import "../utilities/themes";

.project-container {
    @include themed {
        background-color: t($project-background);
    }
    padding-top: 0em;
    min-height: 100% !important;
    text-align: center;
    .ant-col-xs-24 {
        text-align: center;
    }
}

.project-jumbotron {
    background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
    position: relative;
    overflow: hidden;
    height: auto;
    video {
        left: 0;
        top: 0;
        position: absolute;
        margin-top: -5em;
        width: 100%;
    }
    .banner {    
        background: #FFFFFF;
        min-height: 25em;
        opacity: 0.7;
        h1 {
            color: #000000;
            font-size: 5em;
            margin-bottom: 0;
            padding-top: 1.2em;
            padding-bottom: 0.5em;
        }
    }
    p {
        font-size: 1.5em;
        font-style: italic;
        margin-bottom: 0.5em;
    }
    a {
        font-size: 3em;
        padding: 0.5em;
    }
}

.text-container {
    width: 80%;
    margin: auto;
    text-align: left;
    h2 {
        @include themed {
            color: t($main-heading);
        }
        font-size: 1.5em;
        font-weight: 700;
        padding-top: 1em;
        margin-bottom: 0.5em;
        -webkit-animation: None;
        animation: None;
    
    }
}

.techstack-wrapper{
    padding: 1em 2em 1em 2em;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    display: inline-block !important;
    .svg-inline--fa {
        font-size: 4em !important;
        margin: 0em 0.5em 0.1em 0.5em;
    }
    .fa-react {
        color: #61dbfb;
    } 
    .fa-js-square {
        color: #f0d91d;
    }
    .fa-html5 {
        color: #ea6228;
    }
    .fa-css3-alt {
        color: #2da4d6;
    } 
    .fa-sass {
        color: #c76495;
    }
    .fa-bootstrap {
        color: #5d3e88;
    } 
    .fa-git-alt {
        color: #e94e32;
    }
    .fa-github {
        @include themed {
            color: t($github-icon)
        }
    }  
    .fa-trello {
        color: #cecfcf;
    }
    .fa-adobe {
        color: #ff0000;
    }
    .fa-node-js {
        color: #88C349;
    }
    .fa-android {
        color: #32DE84;
    }
    .fa-java {
        color: #517E9D;
    }
}

.demo-slide {
    width: 90%;
    margin: 1em auto 0em auto;
}

.desc-wrapper {
    width: 90%;
    margin: 1em auto 1em auto;
    p {
        @include themed {
            color: t($main-paragraph);
        }
        margin: 0em auto 1em auto;
        font-size: 1.2em;
    }
}

.lessons-wrapper, .contributions-wrapper {
    max-width: 100% !important;
    li {
        @include themed {
            color: t($main-paragraph);
        }
        padding-left: 1em;
        text-align: left;
    }
}

.mockup {
    align-items: flex-start;
    img {
        max-width: 90%;
        object-fit: contain;
        margin: 1em auto 0em auto;
    }
}

.back-btn {
    padding: 2em 0em 3em 0em;
    a {
        font-size: 2em;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .project-container {
        a {
            font-size: 1em;
        }
    }
    .project-jumbotron {
        video {
            margin: 0;
            top: 0;
        }
        .banner {
            min-height: 20em;
            h1 {
                font-size: 3em;
            }
        }
        p {
            font-size: 2em;
        }
        a {
            font-size: 3em;
            padding: 0.5em;
        }
    }
    .techstack-wrapper {
        padding: 0em 2em 0em 2em;
        .svg-inline--fa {
            font-size: 2.5em !important;
            margin: 0em 0.5em 0.1em 0.5em;
        }
    }
    .back-btn {
        padding: 1em 0em 2em 0em;
        a {
            font-size: 2em;
        }
    }
}

@media only screen and (max-width: 768px) {
    .project-container {
        a {
            font-size: 1em;
        }
    }
    .project-jumbotron {
        video {
            margin: 0;
            top: 0;
        }
        .banner {
            min-height: 13em;
            h1 {
                font-size: 2.5em;
            }
        }
        p {
            font-size: 1em;
        }
        a {
            font-size: 2em;
            padding: 0.5em;
        }
    }
    .slides {
        width: 90%;
        margin: 1em auto 0em auto;
    }
    .techstack-wrapper {
        padding: 0em 2em 0em 2em;
        .svg-inline--fa {
            font-size: 2.5em !important;
            margin: 0em 0.5em 0.5em 0.5em;
        }
    }
    .back-btn {
        padding: 1em 0em 2em 0em;
        a {
            font-size: 1.5em;
        }
    }
}