@import "../utilities/themes";

.navbar {
    @include themed() {
        background-color: t($nav-background);
    }
    position: fixed !important;
    width: 100%;
    z-index: 1020;
    .logo {
        margin: 1.5em 2em 1em 1.5em;
        width: 12em;
        cursor: pointer;
    }
    .nav-item {
        @include themed() {
            color: t($nav-text);
        }
        margin: 1vw 1vw 1vw 0vw; 
        padding-bottom: 1vw;
        padding: 1em 1em 0.5em 1em;
        cursor: pointer;
    }
    .nav-item:hover { 
        @include themed() {
            color: t($nav-hover);
            border-bottom: 2px solid t($nav-hover);
        }   
    }
    .nav-item.active { 
        @include themed() {
            color: t($nav-active);
            border-bottom: 2px solid t($nav-active);
        }
    }
    a.exlink {
        @include themed() {
            color: t($nav-text);
        }  
        padding-bottom: 0.5em;
        margin-right: 2vw;
        font-size: 2em;
    }
    a.exlink:hover { 
        @include themed() {
            color: t($nav-active);
        }  
    }
    .hamburger {
        height: 1.8em;
    } 
    .ml-auto {
        margin-right: 5em;
    }
}

@media only screen and (max-width: 321px) {
    .navbar {
        .logo {
            margin: 1.5em 2em 1em 0.5em;
        }
        .ml-auto {
            margin-right: 0em;
        }
    }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
    .navbar {
        .ml-auto {
            margin-right: 0em;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
    .navbar {
        .ml-auto {
            margin-right: 0em;
        }
    }
}

