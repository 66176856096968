@import "../utilities/themes";

.landing-container {
    @include themed() {
        background: linear-gradient(-35deg, rgba(208,166,255,1) 14%, rgba(0,212,255,1) 49%, rgba(191,255,221,1) 77%);
    }
    height: 100vh;
    text-align: center;
    -webkit-animation: AnimationName 7s ease infinite;
    -moz-animation: AnimationName 7s ease infinite;
    -o-animation: AnimationName 7s ease infinite;
    animation: AnimationName 7s ease infinite;
    .continue-link {
        cursor: default;
    }
}

.landing-box {
    border: 2px solid #000000;
    position: absolute;
    justify-content: center;
    left: 2vw;
    right: 2vw;
    top: 2vw;
    bottom: 2vw;
}

@keyframes sunny {
    100% {
      stroke-dashoffset: 0;
    }
}

/* Text Animation */
.handwriting-effect {
    width: 80vw;
    padding: 5vw 1vw 2vw 1vw;
}

#S-Path {
    stroke-dasharray: 1200;
    stroke-dashoffset: 1200;
    animation: sunny 1.5s linear forwards;
}
  
#U-Path {
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    animation: sunny 0.6s linear forwards 1.8s;
}
  
#UN-Path {
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    animation: sunny 0.6s linear forwards 2.1s;
}
  
#N1-Path {
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    animation: sunny 0.6s linear forwards 2.4s;
}
  
#N2-Path {
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    animation: sunny 0.6s linear forwards 3s;
}
  
#Y1-Path {
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    animation: sunny 0.6s linear forwards 3.5s;
}
  
#Y2-Path {
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    animation: sunny 0.6s linear forwards 3.8s;
}
  
#F1-Path {
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    animation: sunny 0.6s linear forwards 4.5s;
}
  
#F2-Path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: sunny 0.8s linear forwards 4.8s;
}
  
#E-Path {
    stroke-dasharray: 1500;
    stroke-dashoffset: 1500;
    animation: sunny 1.5s linear forwards 5.3s;
}
  
#N3-Path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: sunny 0.5s linear forwards 5.8s;
}
  
#G1-Path {
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    animation: sunny 0.5s linear forwards 6.1s;
} 
  
#G2-Path {
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    animation: sunny 0.3s linear forwards 6.4s;
} 
  
/* Continue */
.continue-button {
    width: 20em;
	-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 7s both;
	        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 7s both;
}

.continue-button:hover {
    cursor: url('../images/bitmoji/doIt.png'), auto;
}

@media only screen and (max-width: 768px) {
    .continue-button {
        width: 10em;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-15 19:3:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}
  
@keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

/* Background */
@-webkit-keyframes AnimationName {
    0%{background-position:0% 72%}
    50%{background-position:100% 29%}
    100%{background-position:0% 72%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 72%}
    50%{background-position:100% 29%}
    100%{background-position:0% 72%}
}
@-o-keyframes AnimationName {
    0%{background-position:0% 72%}
    50%{background-position:100% 29%}
    100%{background-position:0% 72%}
}
@keyframes AnimationName {
    0%{background-position:0% 72%}
    50%{background-position:100% 29%}
    100%{background-position:0% 72%}
}