@import "../utilities/themes.scss";

.home-container {
    padding-top: 6em;
    img {
        height: 25em;
        border-radius: 50%;
        padding: 1em;
    }
    p {
        text-align: left;
    }
}

.photography-container {
    padding-top: 7em;

    .gallery {
        max-width: 90%;
        margin: auto;
        img {
            @include themed() {
                background-color: t($photography-frames);
            }  
            width: 28em;
            border-radius: 0.5em;
            padding: 1em 1em 1em 1em;
            margin: 0.5em 0.5em 2em 0.5em;
        }
    }
}

.resume-container {
    padding-top: 7em;
    h1 {
        margin-bottom: 0em;
    }
    img {
        height: 60em;
        padding-bottom: 3em;
    }
    a {
        @include themed() {
            color: t($main-paragraph);
        }
        text-decoration: underline;
        &:hover {
            @include themed() {
                color: t($link-hover);
            }
            text-decoration: underline;
        }
    }
    p {
        @include themed() {
            color: t($main-paragraph);
        }
    }
}

.contact-container {
    padding-top: 7em;
    p {
        @include themed() {
            color: t($nav-text);
        }
    }
    a {
        @include themed() {
            color: t($nav-text);
        }
        text-decoration: underline;
        &:hover {
            text-decoration: underline;
            @include themed() {
                color: t($link-hover);
            }
        }
    }

    .form-wrapper {
        height: auto;
        width: 100%;
        .form-group {
            align-content: center;
        }
        .form-control {
            width: 50%;
            margin: 0 auto;
        }
        .message-box {
            height: 15em !important;
        }
        .btn-secondary {
            width: 10em;
            height: 3em;
            @include themed() {
                background-color: t($button-background);
                border-color: t($button-background);
                color: t($button-text);
            }
            &:hover {
                @include themed() {
                    background-color: t($button-background-hover);
                    border-color: t($button-background-hover);
                    color: t($button-text-hover);
                }
            }
        }
    }
}

.page-not-found-container {
    img {
        margin-top: 8em;
        float: left;
    }
    h1 {
        margin-bottom: 0em;
        font-size: 12em;
        text-align: center;
    }
    .text-wrapper {
        text-align: left;
        bottom: 0;
        right: 0;
        position: absolute;
    }
    p {
        font-size: 1.5em;
        padding-left: 1em;
        padding-right: 1em;
    }
}


@media only screen and (max-width: 768px) {
    .home-container {
        img {
            height: 20em;
            margin: -2em 0em -5em 0em;
            padding: 0em;
        }
    }

    .photography-container {
        .gallery {
            img {
                max-width: 90%;
            }
        }
    }

    .resume-container {
        img {
            height: 40em;
        }
        a {
            @include themed() {
                color: t($link-hover);
            }
        }
        p {
            padding: 0em 1em 0em 1em;
        }
    }

    .contact-container {
        p {
            padding: 0.5em;
        }
        a {
            @include themed() {
                color: t($link-hover);
            }
        }
        .form-wrapper {
            .form-control {
                width: 80%;
                margin: 0 auto;
            }
            .message-box {
                height: 10em !important;
            }
        }
    }

    .page-not-found-container {
        h1 {
            display: None;
        }
        img {
            height: 15em;
            margin-top: 8em;
        }
    }
}
    
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .photography-container {
        .gallery {
            img {
                max-width: 90%;
            }
        }
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1400px) {
    .photography-container {
        .gallery {
            img {
                max-width: 85%;
            }
        }
    }
}


