$gradient-start: 'gradient-start';
$gradient-end: 'gradient-end';
$main-background-1: 'main-background-1';
$main-background-2: 'main-background-2';
$main-background-3: 'main-background-3';
$project-background: 'project-background';
$nav-background: 'nav-background';
$nav-text: 'nav-text';
$nav-hover: 'nav-hover';
$nav-active: 'nav-active';
$home-heading: 'home-heading';
$main-heading: 'main-heading';
$main-paragraph: 'main-paragraph';
$link-hover: 'link-hover';
$photography-frames: 'photography-frames';
$button-background: 'button-background';
$button-text: 'button-text';
$button-background-hover: 'button-background-hover';
$button-text-hover: 'button-text-hover';
$github-icon: 'github-icon';

$themes: (
  light: (
    $gradient-start: #FC00FF,
    $gradient-end: #00DBDE,
    $main-background-1: #d7d7f3,    
    $main-background-2: #9dc0f5,
    $main-background-3: #8ee7e1,
    $project-background: aliceblue,
    $nav-background: #FFFFFF,
    $nav-text: #000000,
    $nav-hover: #1890FF,
    $nav-active: #1890FF,
    $home-heading: #000000,
    $main-heading: #000000,
    $main-paragraph: #000000,
    $link-hover: #1890FF,
    $photography-frames: #FFFFFF,
    $button-background: #FFFFFF,
    $button-text: #000000,
    $button-background-hover: #000000,
    $button-text-hover: #FFFFFF,
    $github-icon: #1A1E22,
  ),
  dark: (
    $gradient-start: #FC00FF,
    $gradient-end: #00DBDE,
    $main-background-1: #252525,
    $main-background-2: #252525,
    $main-background-3: #252525,
    $project-background: #252525,
    $nav-background: #252525,
    $nav-text: #FFFFFF,
    $nav-hover: #1890FF,
    $nav-active: #1890FF,
    $home-heading: #74C1FF,
    $main-heading: #74C1FF,
    $main-paragraph: #FFFFFF,
    $link-hover: #1890FF,
    $photography-frames: #FFFFFF,
    $button-background: #FFFFFF,
    $button-text: #252525,
    $button-background-hover: #74C1FF,
    $button-text-hover: #FFFFFF,
    $github-icon: #FFFFFF,
  ),
);

@mixin themed() {
    @each $theme, $map in $themes {
      .theme--#{$theme} & {
        $theme-map: () !global;
        @each $key, $submap in $map {
          $value: map-get(map-get($themes, $theme), '#{$key}');
          $theme-map: map-merge($theme-map, ($key: $value)) !global;
        }
        @content;
        $theme-map: null !global;
      }
    }
  }
  
@function t($key) {
    @return map-get($theme-map, $key);
}

